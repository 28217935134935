import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Toolbar,
  Stack,
} from "@mui/material";
import FormsList from "../../features/formslist/FormsList";
import { resetFormsLoadingStatus } from "../../api/services/wetlandforms/wetlandFormsSlice";
import ActionButtonsToolbar from "./components/ActionButtonsToolbar";
import ResourcesAndInfo from "../ResourcesAndInfo/ResourcesAndInfo";
import Settings from "../Settings/Settings";
import Header from "../../features/header/Header";
import { defaultDrawerItems } from "../../features/header/Header";
import UserDetails from "../UserDetails/UserDetails";
import Footer from "../../features/footer/Footer";
import ProjectsGrid from "../../features/projects/ProjectsGrid";
import ProjectsSortAndAddBar from "../../features/projects/ProjectsSortAndAddBar";
import Changelog from "../Changelog/Changelog";
import MapPage from "../MapPage/MapPage";
import RegionSelectBar from "../../features/regionSelectBar/RegionSelectBar";
import BatchUpload from "../BatchUpload/BatchUpload";


export default function Home() {
  const dispatch = useDispatch();

  const [drawerTab, setDrawerTab] = useState(parseInt(localStorage.getItem("activeTabIndex")) ?? 0);

  useEffect(() => {
    const activeTabIndex = parseInt(localStorage.getItem("activeTabIndex")) ?? 0;
    setDrawerTab(activeTabIndex);
  }, []);

  const handleDrawerItemClick = (index) => {
    // Index restriction is only temporary, as the other tabs are still WIP
    if (defaultDrawerItems[index].enabled) {
      localStorage.setItem("activeTabIndex", index);
      setDrawerTab(index);
      if (index === 1) {
        dispatch(resetFormsLoadingStatus());
      }
    }
  };


  const renderAppropriateTab = (drawerItem) => {
    // Note: ensure that added tabs are also properly reflected in the Header file
    switch (drawerItem) {
      case 0:
        return (
          <Container sx={{ mt: 2, mb: 2 }}>
            <Stack alignItems="flex-start">
              <ProjectsSortAndAddBar />
              <ProjectsGrid />
            </Stack>
          </Container>
        );
      case 1:
        return (
          <Stack>
            <ActionButtonsToolbar />
            <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
              <FormsList useProjectView={false} />
            </Container>
          </Stack>
        );
      case 2:
        return (
          <MapPage />
        );
      case 3:
        return <BatchUpload />
      case 4:
        return <UserDetails />;
      case 5:
        return <Settings />;
      case 6:
        return <ResourcesAndInfo />;
      case 7:
        return <Changelog />;
      default:
        return (
          <Stack>
            <ActionButtonsToolbar />
            <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
              <FormsList useProjectView={false} />
            </Container>
          </Stack>
        );
    }
  };

  return (
    <Header 
      useHomePageLayout
      selectedDrawerTab={drawerTab}
      onDrawerItemClick={handleDrawerItemClick}
    >
      <Toolbar />
      {(drawerTab === 1) && (
        <RegionSelectBar />
      )}
      {renderAppropriateTab(drawerTab)}
      <Footer />
    </Header>
  );
}
